import { Coeficientes } from "src/components/interfaces/CoachCard";

export const bonus: Coeficientes[] = [
  { Codigo_cc: "1PC", Valor: 0.20 },
  { Codigo_cc: "2PC", Valor: 0.40 },
  { Codigo_cc: "3PC", Valor: 0.60 },
  { Codigo_cc: "4PC", Valor: 0.80 },
  { Codigo_cc: "5PC", Valor: 1.00 },
  { Codigo_cc: "6PC", Valor: 1.20 },
  { Codigo_cc: "7PC", Valor: 1.40 },
  { Codigo_cc: "8PC", Valor: 1.60 },
  { Codigo_cc: "9PC", Valor: 1.80 },
  { Codigo_cc: "10PC", Valor: 2.00 },
  { Codigo_cc: "11PC", Valor: 2.20 },
  { Codigo_cc: "1PC*0.5", Valor: 0.1 },
  { Codigo_cc: "2PC*0.5", Valor: 0.2 },
  { Codigo_cc: "3PC*0.5", Valor: 0.3 },
  { Codigo_cc: "4PC*0.5", Valor: 0.4 },
  { Codigo_cc: "5PC*0.5", Valor: 0.5 },
  { Codigo_cc: "6PC*0.5", Valor: 0.6 },
  { Codigo_cc: "1PC*0.3", Valor: 0.06 },
  { Codigo_cc: "2PC*0.3", Valor: 0.12 },
  { Codigo_cc: "3PC*0.3", Valor: 0.18 },
  { Codigo_cc: "4PC*0.3", Valor: 0.24 },
  { Codigo_cc: "5PC*0.3", Valor: 0.30 },
  { Codigo_cc: "6PC*0.3", Valor: 0.36 },
];

export const coefHibridas: Coeficientes[] = [
  // T-values
  { Codigo_cc: "TB", Valor: 0.30 },
  { Codigo_cc: "T1", Valor: 0.45 },
  { Codigo_cc: "T2a", Valor: 0.50 },
  { Codigo_cc: "T2b", Valor: 0.50 },
  { Codigo_cc: "T3a", Valor: 0.65 },
  { Codigo_cc: "T3b", Valor: 0.65 },
  { Codigo_cc: "T3c", Valor: 0.65 },
  { Codigo_cc: "T3d", Valor: 0.65 },
  { Codigo_cc: "T4a", Valor: 0.80 },
  { Codigo_cc: "T4b", Valor: 0.80 },
  { Codigo_cc: "T4c", Valor: 0.80 },
  { Codigo_cc: "T4d", Valor: 0.80 },
  { Codigo_cc: "T4e", Valor: 0.80 },
  { Codigo_cc: "T5a", Valor: 0.90 },
  { Codigo_cc: "T5b", Valor: 0.90 },
  { Codigo_cc: "T5c", Valor: 0.90 },
  { Codigo_cc: "T5d", Valor: 0.90 },
  { Codigo_cc: "T5e", Valor: 0.90 },
  { Codigo_cc: "T6a", Valor: 1.10 },
  { Codigo_cc: "T6b", Valor: 1.10 },
  { Codigo_cc: "T6c", Valor: 1.10 },
  { Codigo_cc: "T7", Valor: 1.50 },
  { Codigo_cc: "T8", Valor: 1.70 },
  { Codigo_cc: "T9a", Valor: 2.00 },
  { Codigo_cc: "T9b", Valor: 2.00 },

  // R-values
  { Codigo_cc: "RB", Valor: 0.10 },
  { Codigo_cc: "1RB", Valor: 0.15 },
  { Codigo_cc: "2RB", Valor: 0.20 },
  { Codigo_cc: "ROB", Valor: 0.25 },
  { Codigo_cc: "RCB", Valor: 0.25 },
  { Codigo_cc: "R1", Valor: 0.20 },
  { Codigo_cc: "1R1", Valor: 0.35 },
  { Codigo_cc: "2R1", Valor: 0.45 },
  { Codigo_cc: "RD1", Valor: 0.50 },
  { Codigo_cc: "RU1", Valor: 0.55 },
  { Codigo_cc: "RO1", Valor: 0.55 },
  { Codigo_cc: "RC1", Valor: 0.55 },
  { Codigo_cc: "R2", Valor: 0.40 },
  { Codigo_cc: "1R2", Valor: 0.75 },
  { Codigo_cc: "2R2", Valor: 0.95 },
  { Codigo_cc: "RD2", Valor: 1.05 },
  { Codigo_cc: "RU2", Valor: 1.15 },
  { Codigo_cc: "R3", Valor: 0.60 },
  { Codigo_cc: "1R3", Valor: 1.15 },
  { Codigo_cc: "2R3", Valor: 1.45 },
  { Codigo_cc: "RU3", Valor: 1.75 },
  { Codigo_cc: "R4", Valor: 0.80 },
  { Codigo_cc: "1R4", Valor: 1.55 },
  { Codigo_cc: "2R4", Valor: 1.95 },
  { Codigo_cc: "RD4", Valor: 2.15 },
  { Codigo_cc: "RU4", Valor: 2.35 },
  { Codigo_cc: "1R5", Valor: 1.95 },
  { Codigo_cc: "2R5", Valor: 2.45 },
  { Codigo_cc: "RU5", Valor: 2.95 },
  { Codigo_cc: "1R6", Valor: 2.35 },
  { Codigo_cc: "2R6", Valor: 2.95 },
  { Codigo_cc: "RD6", Valor: 3.35 },
  { Codigo_cc: "RU6", Valor: 3.55 },
  { Codigo_cc: "2R7", Valor: 3.45 },
  { Codigo_cc: "RU7", Valor: 4.15 },
  { Codigo_cc: "2R8", Valor: 3.95 },
  { Codigo_cc: "RU8", Valor: 4.75 },
  { Codigo_cc: "2R9", Valor: 4.45 },
  { Codigo_cc: "RU9", Valor: 5.35 },
  { Codigo_cc: "2R10", Valor: 4.95 },
  { Codigo_cc: "RU10", Valor: 5.95 },

  // F-values
  { Codigo_cc: "FB", Valor: 0.05 },
  { Codigo_cc: "F1a", Valor: 0.10 },
  { Codigo_cc: "F1b", Valor: 0.10 },
  { Codigo_cc: "F1c", Valor: 0.10 },
  { Codigo_cc: "F2a", Valor: 0.20 },
  { Codigo_cc: "F2b", Valor: 0.20 },
  { Codigo_cc: "F2c", Valor: 0.20 },
  { Codigo_cc: "F3a", Valor: 0.30 },
  { Codigo_cc: "F3b", Valor: 0.30 },
  { Codigo_cc: "F3c", Valor: 0.30 },
  { Codigo_cc: "F4a", Valor: 0.40 },
  { Codigo_cc: "F4b", Valor: 0.40 },
  { Codigo_cc: "F4c", Valor: 0.40 },
  { Codigo_cc: "F4d", Valor: 0.40 },
  { Codigo_cc: "F4e", Valor: 0.40 },
  { Codigo_cc: "F4f", Valor: 0.40 },
  { Codigo_cc: "F5a", Valor: 0.50 },
  { Codigo_cc: "F5b", Valor: 0.50 },
  { Codigo_cc: "F5c", Valor: 0.50 },
  { Codigo_cc: "F6a", Valor: 0.65 },
  { Codigo_cc: "F6b", Valor: 0.65 },
  { Codigo_cc: "F6c", Valor: 0.65 },
  { Codigo_cc: "F6d", Valor: 0.65 },
  { Codigo_cc: "F7", Valor: 0.75 },
  { Codigo_cc: "F8a", Valor: 0.90 },
  { Codigo_cc: "F8b", Valor: 0.90 },
  { Codigo_cc: "F9", Valor: 1.00 },
  { Codigo_cc: "F10", Valor: 1.30 },

  // AW-values
  { Codigo_cc: "AB", Valor: 0.05 },
  { Codigo_cc: "A1a", Valor: 0.10 },
  { Codigo_cc: "A1b", Valor: 0.10 },
  { Codigo_cc: "A1c", Valor: 0.10 },
  { Codigo_cc: "A1d", Valor: 0.10 },
  { Codigo_cc: "A2a", Valor: 0.15 },
  { Codigo_cc: "A2b", Valor: 0.15 },
  { Codigo_cc: "A3a", Valor: 0.20 },
  { Codigo_cc: "A3b", Valor: 0.20 },
  { Codigo_cc: "A4a", Valor: 0.45 },
  { Codigo_cc: "A4b", Valor: 0.45 },
  { Codigo_cc: "A5", Valor: 0.65 },
  { Codigo_cc: "A6", Valor: 1.15 },
  { Codigo_cc: "A7", Valor: 1.45 },
  { Codigo_cc: "A8", Valor: 1.65 },

  // C-values
  { Codigo_cc: "CB", Valor: 0.10 },
  { Codigo_cc: "CB+", Valor: 0.20 },
  { Codigo_cc: "C1a", Valor: 0.20 },
  { Codigo_cc: "C1b", Valor: 0.20 },
  { Codigo_cc: "C1a+", Valor: 0.30 },
  { Codigo_cc: "C1b+", Valor: 0.30 },
  { Codigo_cc: "C2a", Valor: 0.30 },
  { Codigo_cc: "C2b", Valor: 0.30 },
  { Codigo_cc: "C2c", Valor: 0.30 },
  { Codigo_cc: "C2a+", Valor: 0.40 },
  { Codigo_cc: "C2b+", Valor: 0.40 },
  { Codigo_cc: "C2c+", Valor: 0.40 },
  { Codigo_cc: "C3", Valor: 0.40 },
  { Codigo_cc: "C3+", Valor: 0.50 },
  { Codigo_cc: "C4", Valor: 0.50 },
  { Codigo_cc: "C4+", Valor: 0.60 },
  { Codigo_cc: "C5", Valor: 1.00 },
  { Codigo_cc: "C5+", Valor: 1.10 },
  { Codigo_cc: "C6a", Valor: 1.25 },
  { Codigo_cc: "C6b", Valor: 1.25 },
  { Codigo_cc: "C6a+", Valor: 1.35 },
  { Codigo_cc: "C6b+", Valor: 1.35 },
  { Codigo_cc: "C7", Valor: 1.50 },
  { Codigo_cc: "C7+", Valor: 1.60 },

  //Spin Values
  { Codigo_cc: "SB", Valor: 0.15 },
  { Codigo_cc: "SCB", Valor: 0.35 },
  { Codigo_cc: "SCDB", Valor: 0.40 },
  { Codigo_cc: "S1", Valor: 0.35 },
  { Codigo_cc: "SC1", Valor: 0.80 },
  { Codigo_cc: "SCD1", Valor: 0.85 },
  { Codigo_cc: "S2", Valor: 0.75 },
  { Codigo_cc: "SC2", Valor: 1.60 },
  { Codigo_cc: "SCD2", Valor: 1.65 },
  { Codigo_cc: "S3", Valor: 1.15 },
  { Codigo_cc: "SC3", Valor: 2.40 },
  { Codigo_cc: "SCD3", Valor: 2.45 },
  { Codigo_cc: "S4", Valor: 1.55 },
  { Codigo_cc: "SC4", Valor: 3.20 },
  { Codigo_cc: "SCD4", Valor: 3.25 },
  { Codigo_cc: "S5", Valor: 1.95 },
  { Codigo_cc: "SC5", Valor: 4.00 },
  { Codigo_cc: "SCD5", Valor: 4.05 },
  { Codigo_cc: "S6", Valor: 2.35 },
  { Codigo_cc: "SC6", Valor: 4.80 },
  { Codigo_cc: "SCD6", Valor: 4.85 },
  { Codigo_cc: "S7", Valor: 2.75 },
  { Codigo_cc: "S8", Valor: 3.15 },
  { Codigo_cc: "S9", Valor: 3.55 },
  { Codigo_cc: "S10", Valor: 3.95 },
];

export const soloTRE: Coeficientes[] = [
  { Codigo_cc: "S-TRE1a", Valor: 2.7 },
  { Codigo_cc: "S-TRE1b", Valor: 2.1 },
  { Codigo_cc: "S-TRE2a", Valor: 3.0 },
  { Codigo_cc: "S-TRE2b", Valor: 2.7 },
  { Codigo_cc: "S-TRE3", Valor: 3.2 },
  { Codigo_cc: "S-TRE4a", Valor: 3.0 },
  { Codigo_cc: "S-TRE4b", Valor: 2.5 },
  { Codigo_cc: "S-TRE5a", Valor: 2.4 },
  { Codigo_cc: "S-TRE5b", Valor: 2.1 },
];

export const duetoTRE: Coeficientes[] = [
  { Codigo_cc: "D-TRE1a", Valor: 3.0 },
  { Codigo_cc: "D-TRE1b", Valor: 2.5 },
  { Codigo_cc: "D-TRE2a", Valor: 2.8 },
  { Codigo_cc: "D-TRE2b", Valor: 2.4 },
  { Codigo_cc: "D-TRE3", Valor: 3.1 },
  { Codigo_cc: "D-TRE4a", Valor: 3.2 },
  { Codigo_cc: "D-TRE4b", Valor: 2.7 },
  { Codigo_cc: "D-TRE5a", Valor: 2.3 },
  { Codigo_cc: "D-TRE5b", Valor: 2.1 },
];

export const duetoMTRE: Coeficientes[] = [
  { Codigo_cc: "M-TRE1a", Valor: 2.7 },
  { Codigo_cc: "M-TRE1b", Valor: 2.5 },
  { Codigo_cc: "M-TRE2a", Valor: 2.4 },
  { Codigo_cc: "M-TRE2b", Valor: 2.2 },
  { Codigo_cc: "M-TRE3",  Valor: 3.0 },
];
export const equipaTRE: Coeficientes[] = [
  { Codigo_cc: "T-TRE1a", Valor: 2.5 },
  { Codigo_cc: "T-TRE1b", Valor: 2.3 },
  { Codigo_cc: "T-TRE2a", Valor: 2.6 },
  { Codigo_cc: "T-TRE2b", Valor: 2.3 },
  { Codigo_cc: "T-TRE3a", Valor: 3.1 },
  { Codigo_cc: "T-TRE3b", Valor: 2.9 },
  { Codigo_cc: "T-TRE4", Valor: 2.9 },
  { Codigo_cc: "T-TRE5a", Valor: 2.4 },
  { Codigo_cc: "T-TRE5b", Valor: 2.1 },
];
