import { Console } from "console";
import React, { useEffect, useState } from "react";

import { useHistory } from "react-router-dom";
import { Competition } from "src/components/interfaces/Competition";
import { Figura } from "src/components/interfaces/Figura";
import { PageLayout } from "src/components/page-layout";
import { useGlobal } from "src/contexts/GlobalContext";

const FigurasTable: React.FC = () => {
  const history = useHistory();
  const url = process.env.REACT_APP_API_URL;
  const [figuraData, setFiguraData] = useState<Figura[]>([]);
  const [competitions, setCompetitions] = useState<Competition[]>([]);
  const { userInfo } = useGlobal();
  useEffect(() => {
    fetch(`${url}/figura`)
      .then((response) => response.json())
      .then((figuraData: Figura[]) => {
        if (userInfo.role === "clube") {
          setFiguraData(figuraData.filter((x) => x.Clube === userInfo.clube));
        }
        if (userInfo.role === "admin" || userInfo.role === "referee") {
          setFiguraData(figuraData);
        }
      })
      .catch((err) => {
        console.log(err.message);
      });
      fetch(`${url}/competitions`)
      .then((response) => response.json())
      .then((competitionData: Competition[]) => {
        console.log(competitionData);
        setCompetitions(competitionData);
      })
      .catch((err) => {
        console.log(err.message);
      });
  }, []);

  function findCompetitionName(id:string){
    if(competitions.length > 0){
      return competitions.filter(c => c._id === id)[0].Nome
    }
   return ""
  }
  function deleteFigura(id: string | undefined): void {
    fetch(`${url}/figura/${id}`, { method: "DELETE" })
      .then((response) => {
        if (response.ok) {
          setFiguraData(figuraData.filter((x) => x._id !== id));
        } else {
          console.error("Failed to delete resource");
        }
      })
      .catch((error) => {
        console.error("Error deleting resource:", error);
      });
  }

  function editFigura(id: string | undefined): void {
    history.push(`/figura?id=${id}`);
  }

  return (
    <PageLayout>
      <table className="coach-table">
        <thead>
          <tr>
            <th>Competicao</th>
            <th>Categoria</th>
            <th>Clube</th>
            <th>Número de Atletas</th>
            <th>Ações</th>
          </tr>
        </thead>
        <tbody>
          {figuraData.map((coach, index) => (
            <tr key={coach._id || index}>
              <td>{findCompetitionName(coach.Competicao)}</td>
              <td>{coach.Categoria}</td>
              <td>{coach.Clube}</td>
              <td>{coach.Atletas.length}</td>
              <td>
                <button
                  className="edit-button"
                  onClick={() => editFigura(coach._id)}
                >
                Ver
                </button>
                <button
                hidden={userInfo.role === "referee"}
                  className="delete-button"
                  onClick={() => deleteFigura(coach._id)}
                >
                  Apagar
                </button>
              </td>
            </tr>
          ))}
        </tbody>
      </table>
    </PageLayout>
  );
};

export default FigurasTable;
